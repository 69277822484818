import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

interface SEOProps {
    description?: string,
    lang?: string,
    image?: {
        src : string;
        height :   string;
        width :   string;
    },
    meta?: [],
    keywords?: Array<string>,
    pathname?: string,
    title: string
  }

  interface DefaultQuery {
    site :{
        siteMetadata :{
          title : string;
          siteUrl : string;
          description : string;
          author : string;
          social :{
            twitter : string;
          }
        }
  }
}
type MetaType = { property: string; content: string | number; } | { name: string; content: string; };

export default function SEO({ description , lang = 'pt-BR', image, meta = [], keywords = [], title, pathname = '' } :SEOProps) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data : DefaultQuery) => {
        const metaDescription = description || data.site.siteMetadata.description;
        const metaImage = image && image.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null;
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:creator`,
                content: `@${data.site.siteMetadata.social.twitter}`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: 'google-site-verification',
                content: 'QlRmuLQWttdkbKlZ0ZwIBX3xv0M8ouqTW3wE2Eg_jKI'
              }
            ]
              .concat(metaImage && image ? [{property: `og:image`, content: metaImage},
                {
                  property: `og:image:alt`,
                  content: title,
                },
                {
                  property: 'og:image:width',
                  content: image.width
                },
                {
                  property: 'og:image:height',
                  content: image.height
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                }
              ]   : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
            ])
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}


const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
        social {
          twitter
        }
      }
    }
  }
`